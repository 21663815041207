import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Image } from '@boss/ui';

type Props = {
  children: ReactNode;
  backgroundImg?: string;
  title?: string;
};

const AccountLayout = ({ children, backgroundImg, title }: Props) => {
  const hasBgImage = !!backgroundImg;

  return (
    <>
      {title && (
        <div className="md:h-100 lg:h-112.5 relative left-0 hidden w-full w-full md:block">
          <h1 className={twMerge('relative z-10 mb-10 block pt-5 lg:pt-10', hasBgImage ? 'text-white' : '')}>
            {title}
          </h1>
          {hasBgImage && (
            <div className="absolute left-[calc((100vw-100%)/-2)] top-0 z-0 h-full w-screen">
              <span className="absolute z-10 h-full w-full bg-black opacity-20"></span>
              <Image alt="background account image" className="relative z-0" fill src={backgroundImg} useNext />
            </div>
          )}
        </div>
      )}

      {children}
    </>
  );
};

export default AccountLayout;
