import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { CHANNEL, accountOrderSlugs, accountPageSlugs } from '@boss/constants/b2b-b2c';
import { getEntriesByTag, getTopicContent } from '@boss/contentful-client/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IProduct, product as productService } from '@boss/services';
import { ContentPageFields, Crumble, LocalizedSlugs, OverviewFields } from '@boss/types/b2b-b2c';
import { Presence } from '@boss/ui';
import { TAccountPageType, getAccountPageSlugs, getAccountPageTypeBySlug } from '@boss/utils';

import { useAccount, useAppointments } from '../../client-queries';
import { AccountSkeleton, SEO, WithAuth } from '../../components';
import { PageTypes } from '../../constants';
import { AccountPage } from '../../containers';
import AccountLayout from '../../layout/account';
import Layout from '../../layout/default';
import getServerSideTranslations from '../../utils/getServerSideTranslations';
import { mapNextToContentfulLocale } from '../../utils/localeMapper';
import { getMappedNavigation } from '../../utils/navigation';
import { accountContactInformationType, accountPageConfig, isB2b } from '../../utils/options';

export const getStaticProps = async ({
  preview,
  locale,
}: GetStaticPropsContext<{ slug: string[]; locale: string }>) => {
  if (!locale || locale === 'default') {
    return {
      notFound: true,
    };
  }

  const contentfulLocale = mapNextToContentfulLocale(locale);
  const [navigation, contactInfo, popularThemePages] = await Promise.all([
    getMappedNavigation(contentfulLocale, !!preview),
    getTopicContent({ preview: !!preview, type: accountContactInformationType, locale: contentfulLocale }),
    getEntriesByTag<ContentPageFields | OverviewFields>('popularContactTheme', contentfulLocale),
  ]);

  const mostRecent: IProduct[] = [];

  // These need only fetching on B2B currently
  if (isB2b && process.env.SKIP_LATEST_PRODUCTS !== 'true') {
    for await (const product of await productService.getLatest(contentfulLocale, CHANNEL)) {
      mostRecent.push(product);
    }
  }

  return {
    props: {
      pageType: PageTypes.ACCOUNT,
      preview: !!preview,
      navigation,
      contactInfo,
      locale,
      popularThemePages,
      mostRecent,
      ...(await getServerSideTranslations(locale, ['common', 'account', 'product', 'stores', 'forms'])),
    },
  };
};

export const Account = ({
  navigation,
  locale,
  contactInfo,
  popularThemePages,
  mostRecent,
  preview,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { t } = useTranslation(['account', 'common']);
  const { showBreadcrumb, customHeader } = accountPageConfig;
  const { asPath } = useRouter();

  const [activeTab, setActiveTab] = useState<TAccountPageType>();
  const [crumbles, setCrumbles] = useState<Crumble[]>([{ href: accountPageSlugs[locale], label: 'Account' }]);
  const [mayShowCrumbles, setMayShowCrumbles] = useState<boolean>(true);
  const showCrumbles = showBreadcrumb && mayShowCrumbles;

  const { data: account, isLoading } = useAccount(locale);

  const { data: appointments } = useAppointments(locale);

  const pageTitle = crumbles[crumbles.length - 1]?.label;
  const pageDescription = pageTitle; // TODO: Update with final value once this is known

  // TODO: change this to a public folder image of high quality, chosen by BOSS
  const accountBgImage = '/account_bg_image.jpg';

  const getCurrentPage = () => getAccountPageTypeBySlug(asPath.replace(/^\/+([^?]+).*/, '$1'));

  const buildCrumbles = (current: TAccountPageType, subPage?: string) => [
    crumbles[0],
    { href: getAccountPageSlugs(current)[locale], label: t(`general.navigation.${current}`) },
    ...(subPage ? [{ href: '', label: subPage }] : []),
  ];

  const setSubPage = (subPage: string | undefined) => {
    const current = getCurrentPage();

    setCrumbles(buildCrumbles(current, subPage));
  };

  useEffect(() => {
    const current = getCurrentPage();

    setActiveTab(current);

    if (current === 'ORDERS') {
      const orderId = asPath.split('/')[3]?.split('?')[0];

      setMayShowCrumbles(!orderId);

      setCrumbles(buildCrumbles(current, orderId));
    } else {
      setCrumbles(buildCrumbles(current));
      setMayShowCrumbles(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath, t, locale]);

  const mapAccountPageSlugs = (slugs: LocalizedSlugs, id: string | null) => {
    if (!id) {
      return slugs;
    }

    return Object.entries(slugs).reduce((acc: Record<string, string>, [locale, slug]) => {
      acc[locale] = `${slug}/${id}`;
      return acc;
    }, {});
  };

  const orderId = activeTab === 'ORDERS' ? asPath.split('/')[3]?.split('?')[0] : null;
  const localizedSlugs = activeTab ? mapAccountPageSlugs(getAccountPageSlugs(activeTab), orderId) : accountOrderSlugs;
  const todayDate = new Date();

  todayDate.setHours(0, 0, 0, 0);

  const shownAppointments = appointments
    ?.filter(appointment => {
      return new Date(appointment.startdatetime) >= todayDate;
    })
    .sort((a, b) => new Date(a.startdatetime).getTime() - new Date(b.startdatetime).getTime());

  return (
    <SEO
      breadcrumbs={crumbles}
      description={pageDescription}
      localizedSlugs={localizedSlugs}
      navigation={navigation}
      noFollow
      noIndex
      title={pageTitle}
    >
      <Layout
        crumbles={showCrumbles ? crumbles : undefined}
        localizedSlugs={localizedSlugs}
        navigation={navigation}
        preview={preview}
      >
        <AccountLayout
          backgroundImg={customHeader ? accountBgImage : undefined}
          title={customHeader ? t('myColora') ?? '' : undefined}
        >
          <div
            className={
              customHeader ? 'rounded-tl-30 rounded-tr-30 lg:-mt-85 relative z-20 mt-10 bg-white md:-mt-80' : ''
            }
          >
            {/* Had to add a height container to preserve layout */}
            {!showCrumbles && isB2b && <div className="h-25 pt-7" />}
            <Presence
              id="account-page"
              isLoading={isLoading}
              loader={<AccountSkeleton withNavigation />}
              visible={!!account && !!activeTab}
            >
              <>
                {account && activeTab && (
                  <AccountPage
                    account={account}
                    activeTab={activeTab}
                    appointments={shownAppointments}
                    contactInfo={contactInfo}
                    locale={locale}
                    mostRecent={mostRecent}
                    popularThemePages={popularThemePages}
                    setSubPage={setSubPage}
                  />
                )}
              </>
            </Presence>
          </div>
        </AccountLayout>
      </Layout>
    </SEO>
  );
};

export default WithAuth(Account);
